.main {
    width: 100vh;
    max-width: 80%;
    display: flex;
    flex-direction: column;
}

.bodyMain{
    width: 100vw;
    max-width: 98.99vw !important;
    margin: 0;
    background: linear-gradient(
        90deg,
        #002100 0%,
        #000000 15%,
        #000000 85%,
        #002100 100%
      );
      display: flex;
      justify-content: center;
}

body{
    height: 100vh !important;
    margin: 0 !important;
    padding: 0 !important;
    cursor: default;
}

body{
  margin: 0;
  padding: 0;
}

.reviewsWrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
}

.review {
  display: flex;
  justify-content: center;
  max-width: 400px;
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid rgb(70, 70, 70);
  border-top: 10px solid #24ee25;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite; /* Animation for spinning effect */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.reviewsBackground {
  background: linear-gradient(
    90deg,
    #002100 0%,
    #000000 15%,
    #000000 85%,
    #002100 100%
  );
  width: 100%;
  height: 100%;
}

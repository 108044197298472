@font-face{
    font-family: Mecanica;
    src: url(../assets/fonts/AmericanCaptainPatrius02Fre-G06G.otf)
}


.text {
    font-family: Mecanica, serif;
    color: #19d400;
    text-shadow: -2px 2px 11px rgba(8, 8, 8, 5.92);
    letter-spacing: 1px;
    font-weight: 600;
    position: relative;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 120px;
    margin-bottom: 100px;
    cursor: default;
}

@media (max-width: 540px) {
    .text {
      font-size: 24px !important;
      letter-spacing: 4px;
      position: relative;
      margin: 20px 15px 0;
    }
  }

.main {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile img {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 #24ee25;
  margin-bottom: 30px;
}

.name {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-right: 5px;
  margin-bottom: 20px;
  color: white;
}

.details {
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
}

.details {
  align-items: center;
}

.info,
.email,
.insured {
  display: flex;
  color: white;
}

.insured {
  text-decoration: underline;
}

.detail {
  align-self: flex-start;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.email a,
.info a,
.detail a {
  color: #24ee25;
  text-decoration: none;
}



.contactBackground {
  background: linear-gradient(
    90deg,
    #002100 0%,
    #000000 15%,
    #000000 85%,
    #002100 100%
  );
}

.icon:hover,
.email:hover,
.detail:hover{
    text-decoration: 1px solid white underline;
    scale: 1.08;
    transition: 0.5s;
}

.review {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1.5px solid #24ee25;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 85%;
  align-items: center;
  border-radius: 15px;
  margin-left: 30px;
}

@media only screen and (max-width: 500px) {
  .review {
    width: 90%;
    margin-left: 10px;
  }
}

.review:hover {
  background-color: rgb(214, 214, 214);
  scale: 0.95;
  transition: 0.5s;
  border: 3px solid #24ee25;
  cursor: pointer;
}


.profilePic img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #ccc;
}

.link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
}

.main {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
}

  
  .bodyHead {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 470px;
    overflow: hidden;  /* Ensure content respects the parent's boundaries */
  }
  
  .bodyHead::before {
    content: "";
    position: absolute;
    top: -1%;
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/newengine.png );
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(3.8px); /* Adjust the blur value as needed */
    z-index: -1; /* Place this behind the main content */
  }
  
  /* Ensure child elements are positioned correctly */
  .bodyHead > * {
    position: relative;
    z-index: 1;
  }
  
  .ctaButtonHead {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    font-weight: bold;
    color: white;
    background-color: #059213;
    border: none;
    border-radius: 9px;
    padding: 20px 0px;
    cursor: pointer;
    text-align: center;
    align-self: center;
    width: 200px;
    margin-bottom: 35px;
    z-index: 2;
    transform: scale(1.0);
    transition: 0.6s;
    animation: pulse 2s infinite; /* Adiciona a animação de pulsação */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05); /* Altere para o tamanho desejado durante o pulso */
    }
    100% {
      transform: scale(1);
    }
  }
  
  li {
    list-style: none;
    margin-bottom: 15px;
  }
  
  .top {
    display: flex;
    align-items: center;
    color: #24ee25;
    font-weight: bold;
    margin-bottom: 5px;
    border-bottom: 2px solid #043b04;
    border-left: 2px solid #043b04;
  }

.bodyHead {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 470px;
  overflow: hidden; /* Ensure content respects the parent's boundaries */
}

.bodyHead::before {
  content: "";
  position: absolute;
  top: -1%;
  width: 100%;
  height: 100%;
  background-image: url(../assets/images/newengine.png);
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3.8px); /* Adjust the blur value as needed */
  z-index: -1; /* Place this behind the main content */
}

/* Ensure child elements are positioned correctly */
.bodyHead > * {
  position: relative;
  z-index: 1;
}

.ctaButtonHead {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: bold;
  color: white;
  background-color: #059213;
  border: none;
  border-radius: 9px;
  padding: 20px 0px;
  cursor: pointer;
  text-align: center;
  align-self: center;
  width: 200px;
  margin-bottom: 35px;
  z-index: 2;
  transform: scale(1);
  transition: 0.6s;
  animation: pulse 2s infinite; /* Adiciona a animação de pulsação */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Altere para o tamanho desejado durante o pulso */
  }
  100% {
    transform: scale(1);
  }
}

li {
  list-style: none;
  margin-bottom: 15px;
}

.top {
  display: flex;
  align-items: center;
  color: #24ee25;
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 2px solid #043b04;
  border-left: 2px solid #043b04;
}

.icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.learnMore {
  color: #0ebe26;
  font-weight: bold;
  cursor: pointer;
}

.learnMore:hover {
  text-decoration: underline;
}

.ctaButtonFooter {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: bold;
  color: white;
  background-color: #059213;
  border: none;
  border-radius: 9px;
  padding: 20px 0px;
  cursor: pointer;
  text-align: center;
  align-self: center;
  width: 200px;
  margin-bottom: 35px;
  z-index: 2;
  transform: scale(1);
  transition: 0.6s;
  animation: pulse 2s infinite; /* Adiciona a animação de pulsação */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Altere para o tamanho desejado durante o pulso */
  }
  100% {
    transform: scale(1);
  }
}

.ctaButtonFooter:hover {
  background-color: #10c70a;
  box-shadow: 4px 4px 21px rgba(25, 212, 0, 0.99);
  transition: 0.6s;
  opacity: 0.8;
}

.ctaButtonHead:hover {
  background-color: #10c70a;
  box-shadow: 0px 0px 21px rgba(25, 212, 0, 0.99);
  transition: 0.6s;
  opacity: 0.9;
}

.bodyTitle:hover {
  transform: scale(1.15);
  transition: transform 1.5s;
  cursor: default;
}

.bodyTitle {
  transform: scale(1);
  transition: transform 1.5s;
  display: flex;
  width: 100vw;
  position: relative;
  top: -10%;
  max-width: 540px;
  margin: 50px auto 0;
}

.navbar {
  top: 0;
  z-index: 100; /* Ensure navbar stays above other content, increased further */
  background-color: #ffffff;
  /* Add any other styles for your navbar */
}

.bodyTopicTitle {
  position: relative;
}

.bodyTopicSub {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: 50px;
}

.benefitsList {
  gap: 25px;
}

.benefitsList {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 540px) {
  .benefitsList {
    flex-direction: column;
  }
}

.bodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    #002100 0%,
    #000000 15%,
    #000000 85%,
    #002100 100%
  );
}

.bodyContent {
  margin-top: 50px;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.bodyMainTitle,
.bodySubTitle {
  /* width: 300px; Defina a mesma largura para garantir que ambos os textos compartilhem o mesmo espaço */
  margin: 0 auto; /* Centraliza os contêineres horizontalmente */
}

.bottom {
  color: white;
}

.links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 110px;
  margin: 0 auto !important;
  background: rgb(237,237,237, .1);
  backdrop-filter: blur(06px);
  height: 120px;
  position: sticky;
  top: 0;
  z-index: 10;
}

@media only screen and (max-width: 1040px) {
  .logo {
    display: none;
  }

  .links {
    padding-bottom: 5px;
  }

  .link {
    font-size: 0.9rem !important;
    margin-left: 22px !important;
  }
}

.moreBlur {
  backdrop-filter: blur(30px);
}

.link {
  text-decoration: none;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10px;
  width: 70px;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  margin: 5px 5px;
  filter: blur(0px);
  position: relative;
  left: -5%;
  top: -5% !important;
}

.link:hover {
  color: white;
  text-shadow: -2px 2px 21px rgba(25, 212, 0, 50.99);
  /* background-color: #28592c; */
  visibility: visible;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s;
}

.buttonUnderline {
  position: relative;
  text-decoration: none;
  color: white;
  font-family: "Manier";
  transition: all 0.3s ease;
  cursor: pointer;
}

.buttonUnderline::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  transition: width 0.3s ease;
  border-radius: 100%;
}

.buttonUnderline:hover::before {
  width: calc(100% - 0%);
  left: 0;
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
}

.buttonUnderline.clicked::before {
  width: 100%;
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
  left: 0;
}

/* Efeito de Texto Sublinhado em Botões */
.link.clicked::before,
.link.clicked::before,
.link.clicked::before,
.link.clicked::before,
.link.clicked::before {
  width: 100%;
}

.logo {
  position: relative;
  left: 45%;
  border-radius: 5px;
  height: 45px;
  width: 220px;
  opacity: 95%;
  max-height: 150px;
}

.fbkButton {
  border: none;
  margin: 0;
  margin-right: 20px;
}


@media only screen and (max-width: 540px) {
  .emailHead {
    display: none;
  }
  .smsContainer {
    display: none;
  }
}

@media only screen and (max-width: 1040px) {
  .facebook {
    position: relative;
    left: -3%;
    background: none;
    border: none;
  }
  .fbkLogo {
    height: 20px;
    background: none;
    cursor: pointer;
    background: white;
    padding: 0;
    margin: 0;
  }

  a.emailHead {
    display: none;
  }
}

.smsContainer {
  color: #333;
  text-decoration: none;
  padding: 14px 0;
}

@media only screen and (min-width: 1000px) {
  .smsContainer {
    position: relative;
    color: #333;
    text-decoration: none;
    left: 4%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 180px;
    padding: 14px 0;
    cursor: pointer;
  }
}

.facebook {
  position: relative;
  left: -4.5%;
  background: none;
  border: none;
}

@media only screen and (min-width: 540px) {
  .fbkLogo {
    height: 27px;
    background: none;
    cursor: pointer;
    background: white;
    padding: 0;
    margin: 0;
  }

  .emailHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 234px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
    padding: 14px 0;
  }

  .emailIcon {
    height: 25px;
  }
}

.headSms {
  display: flex;
  border: none;
  background: none;
  flex-direction: row;
  cursor: pointer;
}

.fbkLogo:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.headPhone {
  height: 20px;
  position: relative;
  top: 30%;
  cursor: pointer;
}

.quote {
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  left: 70%;
}

.navHead {
  background-color: rgb(174, 173, 173);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  margin: 0 !important;
}

.tel {
  position: relative;
  cursor: pointer !important;
}

.fbkHeadContainer {
  position: relative;
  left: 8%;
}

.smsContainer,
.emailHead {
  position: relative;
  overflow: hidden; /* Necessário para garantir que os pseudo-elementos não causem problemas de layout */
}

.smsContainer::before,
.smsContainer::after,
.emailHead::before,
.emailHead::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000000; /* Cor da linha */
  transition: transform 0.3s ease;
}

.smsContainer::before,
.emailHead::before {
  top: 0;
  transform: translateX(-100%);
}

.smsContainer::after,
.emailHead::after {
  bottom: 0;
  transform: translateX(100%);
}

.smsContainer:hover::before,
.emailHead:hover::before {
  transform: translateX(0);
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
}

.smsContainer:hover::after,
.emailHead:hover::after {
  transform: translateX(0);
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
}

.buttonName {
  position: relative;
  left: -80%;
}

.quoteButton {
  position: relative;
  overflow: hidden;
  width: 170px;
  left: 9%;
}

.quoteButton p {
  position: relative; /* Adicione essa linha */
  z-index: 1; /* Adicione essa linha */
}

.quoteButton::before,
.quoteButton::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000000;
  transition: transform 0.3s ease;
}

.quoteButton::before {
  top: 0;
  transform: translateX(-100%);
}

.quoteButton::after {
  bottom: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.quoteButton:hover::before {
  transform: translateX(0);
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
}

.quoteButton:hover::after {
  transform: translateX(0);
  text-shadow: -15px 15px 21px rgba(25, 212, 0, 59.99);
}



.secondaryPages {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 110px;
  margin: 0 auto !important;
  background: rgb(237,237,237, .1);
  backdrop-filter: blur(06px);
  height: 120px;
  position: sticky;
  top: 0;
  z-index: 10;
}

@media only screen and (max-width: 1040px) {
  .logo {
    display: none;
  }

  .links {
    padding-bottom: 5px;
  }

  .link {
    font-size: 1.0rem !important;
    margin-left: 22px !important;
  }
}

.headerStyle{
  width: 100px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

* {
  max-width: 100%;
  box-sizing: border-box;
}

#root > * {
  /*margin: 5px 15px;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-photo-album--row > *:hover {
  opacity: 80% !important;
}

/* So headers are shown the same in all pages - with or without vertical scrollbar */
html {
  overflow-y: scroll;
  min-height: 100vh;
  background: linear-gradient(265deg, #ffffff 0%, #c3c3c3 60%, #ffffff 120%);
  background: -webkit-linear-gradient(265deg, #ffffff 0%, #c3c3c3 60%, #ffffff 120%);
  background: -moz-linear-gradient(265deg, #ffffff 0%, #c3c3c3 60%, #ffffff 120%);
  background-repeat: no-repeat;
  background-size: cover;
}
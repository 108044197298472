@font-face {
    font-family: american;
    src: url(../assets/fonts/AmericanCaptainPatrius02Fre-G06G.otf);
}

.text {
    font-family: american; 
    font-size: 22px;
    margin-top: 20px;
    font-weight: 550;
    color: #24ee25;
    margin-bottom: 10px;
    padding-bottom: 5px;
    cursor: default;
    border-bottom: 2px solid #113908;
    
}

.text:hover {
    color: rgb(244, 247, 244);
    border-bottom: 2px solid #ffffff;
    transition: 0.6s;
}

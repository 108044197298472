.main {
    display: flex;
    flex-direction: column;
    /*width: 95%;*/
    margin: 0 auto;
}

.album {
    margin: 20px 0;
}

.modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalImage {
    max-width: 90%;
    max-height: 55vh;
    object-fit: contain;
}

.modalMessage {
    margin: 16px;
    font-size: 1.5rem;
    font-style: italic;
    color: #959595;
    position: relative;
    border-left: 4px solid #ccc;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 1000px;
}

.modalMessage::before {
    content: "“";
    font-size: 40px;
    color: #959595;
    position: absolute;
    top: -10px;
    left: 10px;
}

.galleryBackground{
    background: linear-gradient(
        90deg,
        #002100 0%,
        #000000 15%,
        #000000 85%,
        #002100 100%
      );
}
.text {
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-shadow: -2px 2px 21px rgba(25, 212, 0, 0.62);
    letter-spacing: 9px;
    width: 100%; /* Isso fará com que o subtítulo ocupe a mesma largura que o título principal */
    text-align: center; /* Centraliza o texto dentro do espaço disponível */
    font-weight: bolder;
  }


  @media (max-width: 540px) {
    .text {
      font-size: 18px; /* Ajuste o tamanho da fonte conforme necessário */
      letter-spacing: 2px; /* Ajuste o espaçamento entre letras se necessário */
    }
  }